.custom_search_dropdown {
  height: 42px;

  &-header {
    padding: 15px 25px;
    height: 42px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    letter-spacing: 0.3px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    box-shadow: 2px 2px 5px 0px rgba(57, 76, 96, 0.05);
    transition: border 0.2s ease;

    &:hover {
      border-color: #f1c58d;
    }

    .filter_counting {
      margin-left: 2px;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      color: #777;

      &__selected {
        font-size: 14px;
        margin-left: 6px;
      }
    }

    .arrow {
      position: absolute;
      right: 8px;
      display: block;
      padding: 10px;
      font-size: 10px;
      color: #898989;

      svg {
        display: block;
        width: 1em;
        height: 1em;
        fill: #999;
      }
    }
  }

  &-content {
    display: none;
  }

  .open {
    width: 100%;
    display: block;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 4px 0px rgba(64, 87, 109, 0.07);
    border-radius: 6px;
    margin-top: 20px;
    position: relative;
    z-index: 2;

    .dropdown_search {
      .search_box {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #e7d3c9;
        box-sizing: border-box;

        &-icon {
          padding: 8px 9px;
        }

        &-input {
          width: 100%;
          padding: 0;
          border: none;
          font-size: 14px;
          font-family: Roboto;
          letter-spacing: 0.5px;
          color: #444;
          background-color: transparent;

          &:focus {
            border: none;
            box-shadow: none;
          }
        }
      }

      .mselect {
        height: 250px;
        overflow-y: auto;
        padding-top: 5px;
      }

      &-list {
        &_item {
          padding: 14px 7px;
          cursor: pointer;

          &:hover {
            background-color: #faf5f1;
          }

          &:active {
            opacity: 0.8;
          }

          &-content {
            font-size: 12px;
            line-height: 10px;
            letter-spacing: 0.5px;
            color: #333;
            margin-left: 10px;
            white-space: pre-wrap;
          }
        }
      }
    }
  }
}

.register_search_dropdown {
  width: 100%;
  max-width: calc(100vw - 50px);

  .search_box {
    border-color: #e5e5e5 !important;
    background: #f7f7f7;
  }

  &-header {
    padding-left: 15px;
    height: 46px;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background-color: #f3f3f4;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f9f9f9;
      border: 1px solid #e5e5e5;
    }
  }

  .register_open_content {
    width: 100%;
    max-width: 339px;
    overflow: hidden;
  }
}

@media screen and (min-width: 360px) and (max-width: 767px) {
  .custom_search_dropdown {
    width: 328px;
  }
}
