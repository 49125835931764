.file {
  display: none;
}

.position_relative {
  position: relative;
  margin-bottom: 15px;
  justify-content: space-evenly;
}

i.close_span {
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
}

.product-preview {
  display: flex;
  width: fit-content;
  margin: 0 auto 20px 0;
  flex-wrap: wrap;
  row-gap: 10px;

  .post-image {
    height: 50px;
    width: 50px;
    background-size: cover;
    background-position: center;
  }

  i {
    position: relative;
    left: -5px;
    top: -18px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
  }
}
