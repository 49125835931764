.editorClass {
  background-color: #1e1f20;
  padding: 10px;
  border: 1px solid #ccc;
  min-height: 200px;
}

.toolbarClass {
  border: 1px solid #ccc;
}
