.selected_search-list {
  padding-top: 5px;
  cursor: pointer;

  &_selected {
    display: flex;
    justify-content: space-between;
    padding: 14px 7px;
    background: aliceblue;
    margin-bottom: 5px;

    &:active {
      opacity: 0.8;
    }

    &-title {
      font-size: 12px;
      color: #333;
      margin-left: 10px;
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 18px;
      }
    }
  }
}
