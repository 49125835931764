.file {
  display: none;
}

.image-preview {
  display: flex;
  width: fit-content;
  margin: 0 10px;

  .post-image {
    height: 50px;
    width: 50px;
    background-size: cover;
    background-position: center;
  }

  i {
    position: relative;
    left: -5px;
    top: -18px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
  }
}

.category-index {
  max-width: 100px;
}
