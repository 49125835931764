.login_container {
  display: flex;
  justify-content: center;
  margin: 110px auto 340px;
  align-items: center;
  position: relative;
  width: 466px;
  height: 444px;

  &-inner {
    width: 100%;
    height: 100%;

    &_text {
      margin-bottom: 50px;

      .admin-title {
        text-align: center;
        color: #f3f3f3;
        font-size: 17px;
        letter-spacing: 0.3px;
        line-height: 36px;
        margin: 10px 0 0;
      }
      .login-text {
        color: #f3f3f3;
        height: 32px;
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        line-height: 31px;
        margin-bottom: 20px;

        bold {
          color: #ea4c89;
        }
      }
    }

    .auth-btn-group {
      display: flex;
      justify-content: space-around;
      margin: 0 70px;

      .button-form {
        background-color: rgba(234, 76, 137, 1);
        border-radius: 4px;
        border: none;
        color: #ffffff;
        letter-spacing: 0.02em;
        width: 328px;
        cursor: pointer;
        height: 42px;
      }
    }

    .err {
      color: var(--bs-red);
      text-align: center;
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 768px) {
  .login_container {
    width: 100%;
    padding: 2em;
    margin: 200px auto;
  }
}
